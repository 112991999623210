import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: process.env.APP_NAME,
    appIcon: process.env.APP_ICON,
    themeColor: process.env.THEME_COLOR,
    package: {
      version: process.env.PACKAGE_VERSION,
      author: process.env.PACKAGE_AUTHOR,
      contributors: process.env.PACKAGE_CONTRIBUTORS,
    },
    questions: [
      '雖然沒有感冒，我喉嚨中會有痰。',
      '我會覺得手心、腳掌心或身體會熱熱的。',
      '我會覺得自己怕冷。手腳冰冷或需穿比較多的衣服。',
      '我會覺得頭重、頭昏不舒服。',
      '我會覺得疲倦或無力不想動。',
      '我會覺得口中黏黏的或口水黏稠。',
      '我會覺得身體或下半身重重的。',
      '突然站起來時，我會覺得眼前發黑。',
      '我會覺得疲倦不想說話或沒力氣說話。',
      '我的舌頭或口腔會破。',
      '我會覺得眼睛乾澀，或看不清楚東西。',
      '我的胸、腹部或四肢會"悶痛"不舒服。',
      '我的胸、腹部或四肢會"刺痛"不舒服。',
      '我會覺得睡眠時間夠，但仍想睡覺或睡不飽。',
      '我會覺得氣不夠，需要深呼吸。',
      '我的身體或手腳會有麻木感。',
      '我會覺得胸口悶悶或緊緊的，好像有東西壓著。',
      '我會耳鳴。',
      '我的皮膚會無故出現瘀血烏青。',
      '我的皮膚會乾燥、龜裂、變厚或變硬。',
      '我的身體或四肢會看到扭曲變形的血管(靜脈曲張)。',
      '我會感到腰部、膝蓋或足跟痠軟、疼痛、無力或發冷。',
      '沒有劇烈運動時，我會抽筋。',
      '我會覺得呼吸深度短淺或喘。',
      '我的身體側面或兩側上腹肋骨處會有、脹或疼痛的感覺。',
      '我會口渴、嘴巴乾、嘴唇乾燥龜裂，且喝水後很快又口渴。',
      '我的四肢、身體、臉部或眼睛周圍會浮腫。',
      '平躺時我的呼吸會變喘。',
      '我的臉頰會發紅。',
      '我會覺得喉嚨乾，但嘴巴或嘴唇不會乾燥。',
      '姿勢改變時，我會覺得天旋地轉。',
      '我覺得我的聽力減退。',
      '我吹到風會不舒服。',
      '我的舌苔會厚厚或黏黏的。',
      '我會覺得身體或頭面突然一陣熱熱的。',
      '我會喜歡喝溫熱的東西。',
      '沒有劇烈運動時，我的腰部會容易扭傷。',
      '除了早上第一次解尿外，我的小便顏色深黃或茶色。',
      '我的小便量少。',
      '我的大便乾硬。',
      '我的大便不成形。',
      '我會覺得嘴巴淡淡的沒有味道。',
      '沒有喝很多水，我仍覺得小便量多。',
      '我在天亮前會因拉肚子而起床。',
    ],
    class: [[3], [2], [0, 1, 2], [0, 2], [0, 1], [0], [1, 2], [1]],
  },
  mutations: {},
  actions: {},
  modules: {},
});

<template>
  <div class="home">
    <v-overlay z-index>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <span class="text-h5">
            你還不知道自己是屬於什麼體質嗎？
          </span>
          <br />
          <span class="text-subtitle-2">
            甚麼是體質？對人體有甚麼影響嗎？
          </span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" class="d-flex justify-center">
          <v-btn rounded color="info" class="text-overline text-center" @click="openInfo = true">
            了解更多有關體質資訊
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center">
          <v-btn
            :x-large="$vuetify.breakpoint.mobile"
            rounded
            color="success"
            class="text-h5 text-center"
            @click="startTest"
          >
            開始測驗
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="openInfo" max-width="500" :scrollable="$vuetify.breakpoint.mobile">
        <v-card>
          <v-carousel
            hide-delimiters
            hide-delimiter-background
            show-arrows-on-hover
            continuous
            cycle
            interval="15000"
            height="350"
          >
            <v-carousel-item
              v-for="(constitution, i) in constitutionInfo"
              :key="`constitution_${i}`"
              :class="constitution.color"
            >
              <v-card-title class="text-h4">{{ constitution.name }}</v-card-title>
              <v-card-subtitle class="md-0">{{ constitution.content }}</v-card-subtitle>
              <v-card-text>
                <v-container fluid>
                  <v-row dense>
                    <span class="grey--text text--darken-4">特徵：</span>
                    {{ constitution.features }}
                  </v-row>
                  <v-row dense>
                    <span class="grey--text text--darken-4">大多出現在：</span>
                    {{ constitution.diseases }}
                  </v-row>
                  <v-row dense>
                    <span class="grey--text text--darken-4">建議：</span>
                    {{ constitution.suggestions }}
                  </v-row>
                </v-container>
              </v-card-text>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import info from '@/constitution/info.json';

export default Vue.component(
  'Home',
  Vue.extend({
    name: 'Home',
    data: () => ({
      carousel: 0,
      constitutionInfo: info,
      openInfo: false,
    }),
    methods: {
      startTest() {
        this.$router.push({ path: 'questionnaire' });
      },
    },
  }),
);
</script>

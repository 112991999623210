<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-icon left>{{ $store.state.appIcon }}</v-icon>
      <v-app-bar-title>{{ $store.state.appName }}</v-app-bar-title>
      <v-spacer />
      <v-menu v-model="menuOpen" left offset-y transition="fade-transition">
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon :small="$vuetify.breakpoint.mobile">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-list-item-icon>
              <v-icon>
                {{ $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-white-balance-sunny' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ `主題：${$vuetify.theme.dark ? '暗' : '亮'}` }}
            </v-list-item-title>
          </v-list-item>
          <about-dialog v-model="aboutDialogOpen" @input="$event ? null : (menuOpen = false)">
            <template #activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                <v-list-item-title>關於</v-list-item-title>
              </v-list-item>
            </template>
          </about-dialog>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path" style="height: calc(100vh - 68px)" />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import AboutDialog from '@/components/App/AboutDialog';

export default Vue.extend({
  name: 'App',

  components: { AboutDialog },

  data: () => ({
    menuOpen: false,
    aboutDialogOpen: false,
    openQuestionnaire: false,
  }),
});
</script>
